import React from 'react';
import s from './Loader.module.scss';

export const Loader = () => (
  <div className={s.root}>
    <div/>
    <div/>
    <div/>
    <div/>
  </div>
);
